<template>
  <div>
    <div class="bg-gray-10" style="min-height: 100vh">
      <div class="align-center p-t-100 m-b-50">
        <bb-logo />
      </div>
      <bb-card>
        <bb-loading-overlay :is-loading="loading" />
        <bb-form
          :disabled="disabled"
          :error-text="$t('login.errorText')"
          :loading="loading"
          :submit-text="$t('login.submit')"
          uppercase-buttons
          @submit="login"
        >
          <h4 class="align-center color-gray-50 f-strong">
            {{ $t('login.title') }}
          </h4>
          <bb-input
            id="usernameInput"
            v-model="credentials.username"
            v-validate="'required'"
            :label="$t('login.username')"
            :data-vv-as="$t('login.username')"
            class="align-left"
            name="username"
            type="text"
            @input="clearError"
          />
          <bb-input
            id="passwordInput"
            v-model="credentials.password"
            v-validate="'required'"
            :label="$t('login.password')"
            :data-vv-as="$t('login.password')"
            autocomplete="on"
            class="align-left"
            name="password"
            type="password"
            @input="clearError"
          />
          <p
            v-if="lblError"
            class="color-red align-center f-smallest m-t-30"
          >
            {{ lblError }}
          </p>
          <template #submit="{ disabled, progress, buttonText }">
            <bb-button
              id="loginSubmitButton"
              type="submit"
              display="block"
              :disabled="disabled"
              :class="{ 'bb-button--disabled': progress < 1, 'm-t-40': true, 'm-b-40': true }"
              :label="buttonText"
            />
          </template>
        </bb-form>
        <digital-authentication-methods
          v-if="$isEe() || $isLt()"
          :close-authentication-modal="closeAuthenticationModal"
          :loading="loading"
          :verification-code="verificationCode"
          @initiateDigitalAuthentication="initiateDigitalAuthentication"
          @initiateIdCardAuthentication="initiateIdCardAuthentication"
          @stopDigitalAuthentication="stopDigitalAuthentication"
        />
        <div v-if="!$isInternal()" class="align-center m-t-10">
          <bb-button
            id="forgotPasswordButton"
            class="btn--extra-small"
            color="gray"
            display="link"
            @click="forgotPassword = true"
          >
            {{ $t('login.forgotMyPassword') }}
          </bb-button>
          <br>
        </div>
      </bb-card>
    </div>
    <otp-modal
      :lbl-error="lblOtpError"
      :loading="loading"
      :masked-contact="maskedContact"
      :mfa-type-code="mfaTypeCode"
      :visible="otp"
      @clearError="clearError()"
      @close="otp = false; clearError()"
      @submit="otpLogin"
      @triggerMfa="triggerMfa"
    />
    <forgot-password
      v-if="forgotPassword"
      :lbl-error="lblForgotPasswordError"
      :loading="loading"
      :reset-link="false"
      :reset-link-sent="resetLink"
      @close="forgotPassword = false"
      @submit="resetPassword"
    />
    <country-select
      :countries="countries"
      :visible="showCountrySelection"
      @country-selected="onCountrySelected"
    />
    <aml-video-modal
      v-if="showAmlVideoModal"
      @videoWatched="setAmlTrainingDate"
      @close="onAmlModalClose"
    />
    <questionnaire-modal
      v-if="showQuestionnaireModal"
      @close="showQuestionnaireModal = false"
    />
    <aml-pdf-modal
      v-if="showAmlPdfModal"
      @amlTrainingConfirmed="setAmlTrainingDate"
      @close="onAmlModalClose"
    />
  </div>
</template>

<script>
  import qs from 'query-string';
  import { v4 } from 'uuid';
  import { get } from 'lodash-es';
  import UserController from '@/controllers/UserController';
  import MfaController from '@/controllers/MfaController';
  import { RoleCode, RouteNames, VerificationMethods } from '@/types/common/enums';
  import { BbButton,
           BbForm,
           BbInput,
           BbCard,
           BbLogo, BbLoadingOverlay } from '@bigbank/interface-components';
  import DigitalAuthenticationMethods from '@/components/common/DigitalAuthenticationMethods';
  import OtpModal from '@/components/common/OtpModal';
  import ForgotPassword from '@/components/common/ForgotPassword';
  import { countryUserGroups } from '@/utils/internal/countryUserGroups';
  import CountrySelect from '@/components/common/CountrySelect';
  import AmlVideoModal from '@/components/common/AmlVideoModal.vue';
  import QuestionnaireModal from '@/components/common/QuestionnaireModal';
  import { getShouldShowQuestionnaireModal } from '@/utils/common/getShouldShowQuestionnaireModal';
  import extendValidation from '@/validation/common/extendValidation';
  import { getShouldShowAmlModal } from '@/utils/common/getShouldShowAmlModal';
  import dayjs from 'dayjs';
  import { isLvPostPartner } from '@/utils/lv/isLvPostPartner';
  import AmlPdfModal from '@/views/lt/AmlPdfModal';

  export default {
    name: 'login-view',
    components: {
      BbButton,
      BbForm,
      BbInput,
      BbCard,
      BbLogo,
      BbLoadingOverlay,
      DigitalAuthenticationMethods,
      OtpModal,
      ForgotPassword,
      CountrySelect,
      AmlVideoModal,
      QuestionnaireModal,
      AmlPdfModal,
    },
    inject: [
      '$validator',
    ],
    data () {
      return {
        otp: false,
        credentials: {},
        disabled: true,
        maskedContact: null,
        mfaTypeCode: null,
        loading: false,
        loginErrorMessage: null,
        otpErrorMessage: null,
        otpId: null,
        forgotPasswordErrorMessage: null,
        showFP: false,
        forgotPassword: false,
        resetLink: false,
        verificationCode: null,
        poll: null,
        closeAuthenticationModal: false,
        uncompletedStatus: {
          MOBILE_ID: '1024',
          SMART_ID: '1022',
        },
        loginFailedMessage: {
          MOBILE_ID: this.$t('login.mobileIdInitializingFailed'),
          SMART_ID: this.$t('login.smartIdLoginFailed'),
          ID_CARD: this.$t('login.idCardLoginFailed'),
        },
        initializingFailedMessage: {
          MOBILE_ID: this.$t('login.mobileIdInitializingFailed'),
          SMART_ID: this.$t('login.smartIdInitializingFailed'),
        },
        languageCodes: {
          EE: 'ET',
          LT: 'LT',
        },
        showCountrySelection: false,
        countries: [],
        countryUserGroups,
        adminUserGroups: process.env.VUE_APP_USER_GROUP_ADMIN,
        managerUserGroups: process.env.VUE_APP_USER_GROUP_MANAGER,
        loanSpecialistUserGroups: process.env.VUE_APP_USER_GROUP_LOAN_SPECIALIST,
        showAmlVideoModal: false,
        showQuestionnaireModal: false,
        showAmlPdfModal: false,
      };
    },
    computed: {
      lblError () {
        return this.loginErrorMessage;
      },
      lblOtpError () {
        return this.otpErrorMessage;
      },
      lblForgotPasswordError () {
        return this.forgotPasswordErrorMessage;
      },
    },
    watch: {
      credentials: {
        handler (val) {
          this.disabled = Object.values(val).length < 2;
        },
        deep: true,
      },
    },
    created () {
      this.idCardLogin();

      if (this.$auth.isLoggedIn()) {
        return getShouldShowAmlModal()
          ? this.$router.push({
            name: RouteNames.LOGIN,
          }).catch(() => {})
          : this.$router.push({
            name: RouteNames.APPLICATIONS,
          }).catch(() => {});
      }
    },
    methods: {
      async login () {
        this.loading = true;
        try {
          const response = await this.$auth.login({
            ...this.credentials,
            isInternal: this.$isInternal(),
          });

          if (this.$isInternal()) {
            this.setInitialInternalUserData();
            this.setRoleAndCountry(response.data.userGroups);
            this.loading = false;

            return;
          }
          if (response?.status === 202) {
            this.maskedContact = response.data.maskedContact;
            this.mfaTypeCode = response.data.mfaTypeCode;
            this.otpId = response.data.id;
            this.otp = true;
          } else {
            await this.afterLogin();
          }
          this.loading = false;
        } catch (error) {
          if (this.$isLt() && error.response?.status === 451) {
            this.loading = false;

            return this.$router.push({
              name: RouteNames.UNAVAILABLE,
            }).catch(() => {});
          }

          this.loading = false;
          this.loginErrorMessage = this.$notification.getErrorText(error);
        }
      },
      setInitialInternalUserData () {
        localStorage.user = JSON.stringify({
          roleCode: RoleCode.BIGBANK_SUPER_ADMIN,
          canApplyForGiftCard: false,
          username: 'super.user',
          firstName: 'Super',
          lastName: 'User',
        });
      },
      setRoleAndCountry (userGroups) {
        this.setRole(userGroups);

        const userCountries = this.getUserCountries(userGroups);
        this.countries = userCountries;
        if (userCountries.length > 1) {
          this.showCountrySelection = true;
        } else if (userCountries.length === 1) {
          this.onCountrySelected(userCountries[0].value);
        } else {
          this.loginErrorMessage = 'Not enough rights';
        }
      },
      getUserCountries (userGroups) {
        userGroups = Array.isArray(userGroups) ? userGroups : [];

        return this.countryUserGroups.filter(el => userGroups.includes(el.userGroup));
      },
      onCountrySelected (country) {
        localStorage.country = this.countries.find(c => c.value === country).code;
        localStorage.orgCode = country;
        this.afterLogin();
        extendValidation(this.$validator);
      },
      setRole (userGroups) {
        const adminGroups = this.adminUserGroups.split(',');
        const managerGroups = this.managerUserGroups.split(',');
        const loanSpecialistUserGroups = this.loanSpecialistUserGroups.split(',');

        let role = 'BASIC';

        if (userGroups.some(group => loanSpecialistUserGroups.indexOf(group) >= 0)) {
          role = RoleCode.BIGBANK_LOAN_SPECIALIST;
        }

        if (userGroups.some(group => managerGroups.indexOf(group) >= 0)) {
          role = RoleCode.BIGBANK_MANAGER;
        }

        if (userGroups.some(group => adminGroups.indexOf(group) >= 0)) {
          role = RoleCode.BIGBANK_ADMIN;
        }

        localStorage.role = role;
        this.$rolePrivileges.changeRole(role);
      },
      otpLogin (otp) {
        this.loading = true;
        this.$auth.login({
          otp,
          otpId: this.otpId,
        })
          .then(() => {
            this.afterLogin();
            this.loading = false;
          }).catch(error => {
            this.loading = false;
            this.otpErrorMessage = this.$notification.getErrorText(error);
          });
      },
      getLoggedInUserInfo () {
        return UserController.getCurrentUser().then(response => {
          localStorage.user = JSON.stringify(response.data);
          this.$rolePrivileges.setCountry(window.config.DOMAIN);
          this.$rolePrivileges.changeRole(response.data.roleCode);
          if (process.env.NODE_ENV === 'production') {
            this.$apm.setUserContext({
              username: response.data.username,
            });
          }
        }).catch(err => {
          this.$notification.show(err).error();
        });
      },
      async afterLogin () {
        if (this.$isInternal()) {
          return this.$router.push({
            name: localStorage.role === RoleCode.BIGBANK_LOAN_SPECIALIST
              ? RouteNames.APPLICATIONS
              : RouteNames.ENTITIES,
          }).catch(() => {});
        }

        this.$auth.initializeLosHeartbeat();
        await this.getLoggedInUserInfo();

        if (!this.$userData().roleCode) {
          return;
        }

        if (this.$userData().roleCode === RoleCode.PARTNER_ACCOUNTANT) {
          return this.$router.push({
            name: RouteNames.PAID_COMMISSIONS_REPORT,
          });
        }

        if ([
          RoleCode.PARTNER_NETS_ADMIN,
          RoleCode.PARTNER_NETS_REGULAR,
        ].includes(this.$userData().roleCode)) {
          return this.$router.push({
            name: RouteNames.ACCESS_BLOCKING,
          });
        }

        if (getShouldShowQuestionnaireModal()) {
          this.showQuestionnaireModal = true;
          this.loading = false;

          return;
        }

        if (getShouldShowAmlModal()) {
          if (this.$isFi() || this.$isEe()) {
            this.showAmlVideoModal = true;
          } else if (this.$isLt()) {
            this.showAmlPdfModal = true;
          }
          this.loading = false;

          return;
        }

        if (this.$isLv() && !isLvPostPartner() && (
          !this.$userData().amlTrainingDate ||
          this.$userData().amlTrainingDate &&
          Math.abs(dayjs(this.$userData().amlTrainingDate).diff(new Date, 'day')) >= 335
        )) {
          this.loading = false;

          return this.$router.push({
            name:  RouteNames.AML_TEST,
          });
        }

        if (this.$route.query.externalId) {
          return this.$router.push({
            name:  RouteNames.APPLICATION,
            query: {
              externalId: this.$route.query.externalId,
            },
          });
        }

        return this.$router.push({
          name:  RouteNames.APPLICATIONS,
        }).catch(() => {});
      },
      triggerMfa () {
        return MfaController.resend(this.otpId).then(response => {
          this.maskedContact = response.data.maskedContact;
          this.mfaTypeCode = response.data.mfaTypeCode;
          this.otpId = response.data.id;
        }).catch(err => this.$notification.show(err).error());
      },
      resetPassword (usernameOrPersonalCode) {
        this.loading = true;
        UserController.requestPasswordResetLink({
          usernameOrPersonalCode,
        }).then(() => {
          this.loading = false;
          this.resetLink = true;
        }).catch((error) => {
          this.forgotPasswordErrorMessage = this.$notification.getErrorText(error);
          this.loading = false;
        });
      },
      clearError () {
        this.loginErrorMessage = null;
        this.otpErrorMessage = null;
      },
      idCardLogin () {
        if (this.$isEe() && this.$route.query.authSessionId) {
          if (this.$route.query.state === localStorage.state) {
            this.loading = true;

            return this.pollDigitalAuthenticationStatus({
              method: VerificationMethods.ID_CARD,
              sessionId: this.$route.query.authSessionId,
            });
          } else {
            this.loading = false;
            this.loginErrorMessage = this.$notification.getErrorText();
          }
        }

        if (this.$isEe() && this.$route.query.errorCode) {
          this.loading = false;
          this.loginErrorMessage = this.$t('login.idCardNotFound');
        }
      },
      initiateIdCardAuthentication () {
        localStorage.state = v4();
        const parameters = {
          successUrl: `${window.location.origin}/log-in?state=${localStorage.state}`,
          errorUrl: `${window.location.origin}/log-in`,
        };
        this.loading = true;
        window.location.href = `${window.config.ID_CARD_LOGIN_REDIRECT_URL}?${qs.stringify(parameters)}`;
      },
      initiateDigitalAuthentication ({
        method,
        personalIdentityCode,
        phoneNumber,
      }) {
        this.loading = true;
        this.initiateAuthentication({
          method,
          personalIdentityCode,
          phoneNumber,
        })
          .then(({ data }) => {
            this.verificationCode = data.verificationCode;
            this.pollDigitalAuthenticationStatus({
              method,
              sessionId: data.sessionId,
            });
          })
          .catch(err => {
            this.loading = false;

            if (err.response && err.response?.status === 500) {
              return this.$notification
                .show(this.loginFailedMessage[method])
                .error();
            }

            this.$notification.show(err).error();
          });
      },
      initiateAuthentication ({ method, personalIdentityCode, phoneNumber }) {
        if (method === VerificationMethods.MOBILE_ID) {
          return this.$auth.initiateMobileIdAuthentication({
            languageCode: this.languageCodes[this.$domain()],
            personalIdentityCode,
            phoneNumber: phoneNumber.replace(/\s+/g, ''),
          });
        }

        return this.$auth.initiateSmartIdAuthentication({
          countryCode: this.$domain().toUpperCase(),
          personalIdentityCode,
        });
      },
      pollDigitalAuthenticationStatus ({ method, sessionId }) {
        this.loading = true;
        this.poll = setTimeout(() => {
          this.$auth
            .getDigitalAuthenticationStatus({
              sessionId,
              isSmartId: method === VerificationMethods.SMART_ID,
              isMobileId: method === VerificationMethods.MOBILE_ID,
              isIdCard: method === VerificationMethods.ID_CARD,
            })
            .then(() => {
              this.loading = false;
              this.afterLogin();
            })
            .catch(err => {
              if (
                this.containsCode(err.response, this.uncompletedStatus[method]) &&
                this.poll &&
                method !== VerificationMethods.ID_CARD
              ) {
                return this.pollDigitalAuthenticationStatus({
                  method,
                  sessionId,
                });
              }

              this.closeAuthenticationModal = true;
              this.loading = false;
              clearTimeout(this.poll);

              if (this.containsCode(err.response, '1001')) {
                return this.$notification.show(this.$t('Failure - user does not exist.')).error();
              }
              this.$notification.show(this.loginFailedMessage[method]).error();
            });
        }, 1000);
      },
      stopDigitalAuthentication () {
        this.loading = false;
        this.closeAuthenticationModal = false;
        clearTimeout(this.poll);
      },
      containsCode (response, code) {
        return !!get(response, 'data.errors', []).some(
          error => error.code === code,
        );
      },
      async setAmlTrainingDate () {
        const userInfo = JSON.parse(localStorage.user);
        userInfo.amlTrainingDate = new Date();
        localStorage.user = JSON.stringify(userInfo);

        await UserController.updateUser(userInfo.id, userInfo);

        return this.$router.push({
          name: RouteNames.APPLICATIONS,
        }).catch(() => {});
      },
      onAmlModalClose () {
        if (this.$isFi() || this.$isEe()) {
          this.showAmlVideoModal = false;
        } else if (this.$isLt()) {
          this.showAmlPdfModal = false;
        }
        this.$router.push({
          name: RouteNames.APPLICATIONS,
        }).catch(() => {});
      },
    },
  };
</script>
